<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">Privacy Policy</h1>
            </div>
            <div class="box b-shdw-3">
                <div class="content" >
                    <h3>Data Protection Privacy Notice</h3>

                    <h4>Introduction</h4>

                    <p>
                        Rush Insurance Services Limited are devoted to protecting and respecting your privacy.
                    </p>
                    <p>
                        We recognise our responsibility to treat your personal information with care and to comply with
                        all relevant legislation, in particular the UK Data Protection Regulation (UK GDPR) tailored by 
                        Data Protection Act 2018 and the EU General Data Protection Regulation (GDPR) which we refer to 
                        as the legislation in this document.
                        <br>This notice covers our requirement to provide you with information on how and why we use your
                        personal data and of your rights under the legislation.
                    </p>

                    <h4>Data Controller</h4>

                    <p>
                        If we have provided you with a quotation and/or administer your insurance policy, we are classed
                        as the data controller which means we process your data. The contact details are
                        shown at the foot of this notice.<br>Your data may be passed to other parties, including Insurers,
                        and/or medical underwriters for the purposes of arranging insurance. These parties could also
                        be data controllers and where necessary will issue their own Data Protection Privacy Notices.
                    </p>

                    <h4>Personal Information</h4>

                    <p>
                        You will be required to provide personal information such as;
                    </p>

                    <ul>
                        <li>
                            <p>Name and date of birth</p>
                        </li>
                        <li>
                            <p>Postal address, email and contact telephone number</p>
                        </li>
                        <li>
                            <p>Details of your travel plans</p>
                        </li>
                        <li>
                            <p>Information about any pre-existing medical conditions</p>
                        </li>
                        <li>
                            <p>Payment details where required</p>
                        </li>
                        <li>
                            <p>Other information you give us in response to Marketing</p>
                        </li>
                    </ul>

                    <p>
                        We will use personal information about you mainly to;
                    </p>

                    <ul>
                        <li>
                            <p>Provide you with a travel insurance quotation</p>
                        </li>
                        <li>
                            <p>Arrange and administer your policy</p>
                        </li>
                        <li>
                            <p>Service your policy including but not limited to claims and complaints</p>
                        </li>
                        <li>
                            <p>Inform you about our products and services</p>
                        </li>
                        <li>
                            <p>Statistical analysis</p>
                        </li>
                        <li>
                            <p>Provide access to internal systems</p>
                        </li>
                        <li>
                            <p>Record training results</p>
                        </li>
                    </ul>

                    <p>
                        Some of the personal information we ask you to provide may be sensitive as defined in the
                        legislation, e.g. you may have to give us information about your medical history or criminal
                        convictions.<br>We are allowed under the legislation to collect such information for specified 
                        insurance purposes without your specific consent but it will only be used for the
                        purposes set out above.<br>If you give us information about another person, in doing so you
                        confirm that they have given you permission to provide it to us and that we may use their
                        personal data in the same way as your own as set out in this notice.
                    </p>

                    <h4>Legal Basis for processing your Personal Information</h4>

                    <p>
                        We are required to have a lawful basis in order to process your personal data and the relevant
                        bases which we use are shown below.
                    </p>

                    <h4>Purpose of processing and Lawful Basis</h4>

                    <p>
                        Providing quotations; arranging and administering insurance policies - Necessary for the performance
                        of an insurance contract, including but not limited to claims and complaints.
                    </p>
                    <p>
                        Provision of information on products and services (Marketing) - Our legitimate interests or your 
                        explicit consent.
                    </p>
                    <p>
                        To notify you of changes in our service - Our Legal and Regulatory obligations.
                    </p>
                    <p>
                        To prevent and detect fraud, money laundering and other financial crimes - Our Legal and Regulatory obligations.
                    </p>
                    <p>
                        To meet general legal or regulatory obligations - Our Legal and Regulatory obligations.
                    </p>
                    <p>
                        Statistical analysis - Our legitimate interests, to refine and enhance the products and pricing which we can offer.
                    </p>
                    <p>
                        Provide access to internal systems - Necessary for the performance of, but not limited to, policy sales, administration and reporting.
                    </p>
                    <p>
                        Record training results - To comply with our regulatory obligations.
                    </p>

                    <h4>Marketing</h4>

                    <p>
                        We may contact you by email, text, telephone, mail or other agreed means to keep you up to date
                        about our products and services. The legislation allows us to do this in our own commercial
                        interests for certain communications with previous customers. In other circumstances we can
                        only do so with your explicit consent. In all cases you can opt out from receiving such
                        communications at any time.
                    </p>

                    <h4>Disclosure of your Personal Information</h4>

                    <p>
                        As a necessary part of providing you with the services described above we may need to disclose
                        your personal data to other third parties. These may include, Software companies, Insurers,
                        other Insurance Intermediaries, Underwriters, Insurance Industry databases, Government databases,
                        Credit agencies, Regulatory authorities and the Police/other law enforcement bodies and this
                        will be to assist with fraud prevention and detection.
                    </p>

                    <h4>Retention Period</h4>

                    <p>
                        Your data will not be retained for longer than is necessary and will be managed in accordance
                        with our data retention policy. In most cases the period will be for a maximum of 7 years
                        following the expiry of an insurance contract unless we are required to retain the data for a
                        longer period due to business, legal or regulatory requirements.
                    </p>

                    <h4>International transfers of data</h4>

                    <p>
                        We may transfer your personal data to destinations outside the European Economic Area (EEA).
                        Where we do we will ensure that it is treated securely and in accordance with the legislation.
                    </p>

                    <h4>Your Rights</h4>

                    <p>
                        Under the legislation you have the following rights in relation to our processing of your
                        personal data: -
                    </p>

                    <ol>
                        <li>
                            <p>The right to be informed about how we use your personal data (This Privacy Notice);</p>
                        </li>
                        <li>
                            <p>
                                The right to see a copy of the personal information we hold about you. (In most cases
                                this will be free of charge);
                            </p>
                        </li>
                        <li>
                            <p>The right to have personal information rectified if inaccurate or incomplete;</p>
                        </li>
                        <li>
                            <p>
                                The right of erasure of your personal information where there is no compelling reason
                                for its continued processing;
                            </p>
                        </li>
                        <li>
                            <p>
                                The right to restrict processing in certain circumstances, e.g. if its accuracy is being
                                contested;
                            </p>
                        </li>
                        <li>
                            <p>
                                The right to data portability which, subject to certain conditions, allows you to
                                obtain and reuse your personal data across different services;
                            </p>
                        </li>
                        <li>
                            <p>
                                The right to object to certain processing including for the purposes of direct marketing;
                            </p>
                        </li>
                        <li>
                            <p>Rights to information in relation to automated decision making and profiling.</p>
                        </li>
                    </ol>

                    <h4>Complaints / Contact us</h4>

                    <p>
                        If you have a complaint about how we use your personal information, please contact us at the
                        address below. You also have the right to lodge a complaint with the Information
                        Commissioner's Office at any time.
                    </p>

                    <p>
                        For further information on this Privacy Notice, to access your personal information or to
                        exercise any of your other rights, please contact The Data Protection Officer, Rush Insurance
                        Services Limited at Beckwith House, 8th Floor, 1 Wellington Road, Stockport, SK4 1AF or email
                        compliance@rushinsurance.co.uk or telephone 0333 400 7780.
                    </p>

                    <h4>Data Protection Notice of White Horse Insurance Ireland dac.</h4>

                    <p>
                        White Horse Insurance Ireland dac holds your personal information in accordance with all
                        applicable data protection laws.
                    </p>

                    <p>
                        To administer your policy White Horse Insurance Ireland dac will collect and use information
                        about you provided by you and this notice applies to anyone who is insured under this Insurance
                        policy and whose personal information may be processed for the provision of insurance and related
                        services.
                    </p>

                    <p>
                        Personal information may be used by us for the purposes of administering your policy; including
                        decision making on provision of insurance cover, underwriting, processing and claims handling.
                        We may also use your personal information for other related matters such as customer service,
                        analysis, complaints handling and the detection and prevention of crime. The information you
                        have supplied may be passed to other insurers and reinsurers for underwriting and claims purposes
                        or to other third-party service providers used by us in fulfilling your insurance contract.
                    </p>

                    <p>
                        We may send your personal information in confidence to other companies who provide services to
                        us for processing and storage. This may mean sending information to countries outside of the
                        United Kingdom, European Union or European Economic Area that may not have the same levels of
                        privacy legislation as in the United Kingdom, European Union or European Economic Area. When we
                        do this, we will ensure that we transfer the data securely and accordingly to regulatory
                        requirements. By buying this insurance policy, you agree to us using your personal information
                        in this way.
                    </p>

                    <p>
                        You have various rights in relation to personal information that is held by us, including the
                        right to request access to your personal information, the right to correct inaccurate personal
                        information, or the right to request the deletion or suppression of personal information where
                        this is not restricted by any conflicting legitimate interest.
                    </p>

                    <p>
                        This notice explains certain aspects of how we use your information and what rights you have in
                        relation to your personal information, however you can obtain more information about how we use
                        your data by reviewing our full Privacy Policy. Our Privacy Policy is available on our website
                        <a href="http://www.whitehorseinsurance.eu/" >www.whitehorseinsurance.eu</a>.
                    </p>

                    <p>
                        Your data will be treated in accordance with our Privacy Policy.
                    </p>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "PrivacyPolicy",
        mixins: [ contentAPI, page ]
    }
</script>

<style lang="scss" scoped>
    .level-item {
        padding: 40px 0 40px 0;
    }

    .content /deep/ {
        h3 {
            color: $c2g_blue;
            font-weight: bold;
        }
        h4 {
            color: $c2g_orange;
            margin-bottom: 8px !important;
        }
        p {
            margin: 0 0 15px 0;
        }
    }
</style>